<template>
  <transition>
    <div class="d-inline-flex p-3 ">
      <button type="button" v-on:click="logout" class="btn btn-tertiary">
        <font-awesome-icon class="mr-1" icon="sign-out-alt" transform="shrink-1"/> Logout
      </button>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {}
  },
  computed: {
    ...mapState({}),
  },
  methods: {
    logout: function () {
      this.$store
          .dispatch('auth/logOut')
          .then(() => {
            this.$router.push({ name: 'Root' })
          })
          .catch(() => {})
    },
  },
  created () { }
}
</script>

<style lang="scss">
</style>
