<template>
  <footer class="p-3">
    <div class=" d-block credits">
      <span>Copyright 1998-{{ currentYear }} Divvy Engagement Solutions</span>
    </div>
  </footer>
</template>

<script>
export default {
  data () {
    return {
      currentYear: new Date().getFullYear(),
    }
  }
}
</script>

<style lang="scss">
</style>
