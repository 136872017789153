var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "p-3" }, [
    _c("div", { staticClass: " d-block credits" }, [
      _c("span", [
        _vm._v(
          "Copyright 1998-" +
            _vm._s(_vm.currentYear) +
            " Divvy Engagement Solutions"
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }