var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", [
    _c("div", { staticClass: "d-inline-flex p-3 " }, [
      _c(
        "button",
        {
          staticClass: "btn btn-tertiary",
          attrs: { type: "button" },
          on: { click: _vm.logout }
        },
        [
          _c("font-awesome-icon", {
            staticClass: "mr-1",
            attrs: { icon: "sign-out-alt", transform: "shrink-1" }
          }),
          _vm._v(" Logout ")
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }